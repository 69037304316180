
import {
	FETCH_MAILING_TEMPLATES_REQUEST,
	FETCH_MAILING_TEMPLATES_SUCCESS,
	FETCH_MAILING_TEMPLATES_FAILURE,
	POST_MAILING_REQUEST,
	POST_MAILING_SUCCESS,
	POST_MAILING_FAILURE,
} from './mailingTypes';
import { selectAuthHeaders } from 'src/redux/auth/authReducers';
import endpoints from 'src/helpers/endpoints';
import { api } from 'src/helpers/init-axios';
import { sleep } from 'src/helpers';

export const fetchMailingTemplatesRequest = () => {
	return {
		type: FETCH_MAILING_TEMPLATES_REQUEST,
	}
}

export const fetchMailingTemplatesSuccess = (payload) => {
	return {
		type: FETCH_MAILING_TEMPLATES_SUCCESS,
		payload: payload,
	}
}

export const fetchMailingTemplatesFailure = (payload) => {
	return {
		type: FETCH_MAILING_TEMPLATES_FAILURE,
		payload: payload,
	}
}

export const postMailingRequest = () => {
	return {
		type: POST_MAILING_REQUEST,
	}
}
export const postMailingSuccess = (payload) => {
	return {
		type: POST_MAILING_SUCCESS,
		payload: payload,
	}
}
export const postMailingFailure = (payload) => {
	return {
		type: POST_MAILING_FAILURE,
		payload: payload,
	}
}

export const fetchMailingTemplates = () => {
	return function(dispatch, getState) {
		const state = getState();
		const headers = selectAuthHeaders(state);
		dispatch(fetchMailingTemplatesRequest())
		api.get(
			endpoints.fetchMailingTemplates(),
			headers
		).then(rsp => {
			dispatch(fetchMailingTemplatesSuccess(rsp.data))
		}).catch(err => {
			dispatch(fetchMailingTemplatesFailure(err.message))
		})
	}
}

export const postMailing = (payload) => {
	return async function(dispatch, getState) {
		try {
			const state = getState();
			const headers = selectAuthHeaders(state);
			dispatch(postMailingRequest())

			await sleep(1000);
			const rsp = await api.post(
				endpoints.postMailing(), payload, headers
			)
			await sleep(1000);
			return dispatch(postMailingSuccess({
				status: rsp.status,
				statusText: rsp.statusText,
			}))
		} catch (err) {
			return dispatch(postMailingFailure(err.message))
		}
	}
}