
import { useNavigate } from "react-router-dom";
import MyImage from 'src/media/ifosac-logo.png';

export function LogoComponent(props) {
	let navigate = useNavigate();
	const handleImageOnClick = (e => {
		navigate('/')
	})
	return (
		<div className="header-logo">
			<figure>
				<img src={MyImage}
					alt="International Freight Operator"
					onClick={handleImageOnClick} />
			</figure>
		</div>
	)
}