

import React from 'react';
import {
	Route,
	Routes as Switch,
} from 'react-router-dom';
import {
	RatesContainer as Rates,
} from 'src/containers/rates/RatesContainer.js';


export const RatesRouter = () => (
	<Switch>
		<Route
			key="rates"
			exact path="/rates"
			element={<Rates></Rates>}>
		</Route>
	</Switch>
);
