

import React from 'react';
import {
	Route,
	Routes as Switch,
} from 'react-router-dom';
import {
	ProformContainer as Proform,
} from 'src/containers/proform/ProformContainer.js';

export const ProformRouter = () => (
	<Switch>
		<Route
			key="proform-air"
			exact path="/proform/air"
			element={<Proform></Proform>}>
		</Route>
		<Route
			key="proform-ocean"
			exact path="/proform/ocean"
			element={<Proform></Proform>}>
		</Route>
		<Route
			key="proform-land"
			exact path="/proform/land"
			element={<Proform></Proform>}>
		</Route>
	</Switch>
)
