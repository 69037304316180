import 'src/components/ButtonComponent.scss';

export function ButtonComponent(props) {
	return (
		<span className={props.className||'control-button'}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a href={props.href||'#'} 
				rel="noreferrer" 
				target={props.href ? '_blank': null} 
				onClick={props.onClick} >
				{props.text}
			</a>
		</span>
	)
}