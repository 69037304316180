
import { homeSettings } from 'src/constants';
import { FontIcon } from 'src/components';

const LocationMap = function(props) {
	const settings = props.settings;
	const googleMapSrc = `https://www.google.com/maps/embed/v1/place?q=${settings.googlePlaceId}&key=${settings.googleApiKey}`;
	return (
		<div className="location-map">
			<iframe
				title="google-location"
				style={{ border: "0" }}
				allowFullScreen=""
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
				src={googleMapSrc}
				>
			</iframe>
		</div>
	)
}

const LocationDetailItem = function(props){
	return (
		<div className="location-details-item">
			<FontIcon icon={props.item.icon}></FontIcon>
			<span>
				<h2>{props.item.label}</h2>
				<p>{props.item.value}</p>
			</span>
			<hr></hr>
		</div>
	)
}
const LocationDetails = function(props){
	const settings = props.settings;
	const items = [
		{ 
			id: 1,
			icon: 'fa-solid fa-location-dot',
			label: 'Ubicación', value: settings.address},
		{ 
			id: 2,
			icon: 'fa-regular fa-calendar-days',
			label: 'Horarios de Atención', value: settings.workingHours },
		{ 
			id: 3,
			icon: 'fa-solid fa-phone',
			label: 'Teléfono', value: settings.phone },
		{ 
			id: 4,
			icon: 'fa-solid fa-envelope',
			label: 'Email', value: settings.email },
	]
	return (
		<div className="location-details">
			{ items.length ? items.map(item => {
				return (
					<LocationDetailItem
						key={item.id}
						item={item}>
					</LocationDetailItem>
				)
			}):null}
		</div>
	)
}

export function MainLocationContainer(props) {
	const settings = homeSettings.footer;
	return (
		<div
			ref={props.reference}
			id={props.id || null}
			className={props.className || null}>
			<LocationDetails settings={settings}></LocationDetails>
			<LocationMap settings={settings}></LocationMap>
		</div>
	)
}