
import React from 'react';
import {
	Route,
	Routes as Switch,
} from 'react-router-dom';
import {
	TrackingContainer as Tracking,
} from 'src/containers/tracking/TrackingContainer.js';

export const TrackingRouter = () => (
	<Switch>
		<Route exact path="/tracking"
			element={<Tracking></Tracking>}>
		</Route>
	</Switch>
);