
import { useNavigate } from 'react-router-dom';
import { homeSettings } from 'src/constants';
import { Button } from 'src/components';
import { ProformRouter } from 'src/routes';


const ProformGallery = function(props){
	const items = props.items;
	const navigate = useNavigate();

	const proformHandler = (e, proformType) => {
		e.preventDefault();
		const route = ProformRouter();
		const path = route.props.children.filter(x=> x.key === proformType );
		if (path.length){
			navigate(path[0].props.path);
		}
	}

	return (
		<div className='proform-gallery'>
			{items.length? items.map(item => {
				return (<article key={item.id}>
					<figure>
						<img src={item.image.src} alt={item.image.alt} />
					</figure>
					<p>{item.paragraph}</p>
					<Button
						onClick={ (e)=> proformHandler(e, item.urlTag)}
						text={item.buttonLabel}>
					</Button>
				</article>)
			}):null}
		</div>
	)
}

const ProformCard = function(props) {
	return (
		<div className='proform-content'>
			<h2>{props.settings.name}</h2>
			<p>{props.settings.description}</p>
		</div>
	)
}

export function MainProformContainer(props) {
	const settings = homeSettings.proform;
	return (
		<div
			ref={props.reference}
			id={props.id ? props.id : null}
			className={props.className ? props.className: null}
			>
			<ProformCard settings={settings}>
			</ProformCard>
			<ProformGallery
				items={settings.items}>
			</ProformGallery>
		</div>
	)
}