
import {
	FETCH_PROFORM_FILES_REQUEST,
	FETCH_PROFORM_FILES_SUCCESS,
	FETCH_PROFORM_FILES_FAILURE,
} from './proformTypes';

const initialState = {
	loading: false,
	proformFiles: [],
	error: null,
	proformCount: 0,
}

const proformReducer = (state = initialState, action ) => {
	switch(action.type) {
		case FETCH_PROFORM_FILES_REQUEST: return {
			...state,
			loading: true,
		}
		case FETCH_PROFORM_FILES_SUCCESS: return {
			...state,
			loading: false,
			error: '',
			proformFiles: action.payload,
		}
		case FETCH_PROFORM_FILES_FAILURE: return {
			...state,
			loading: false,
			error: action.payload,
			proformFiles: [],
		}
		default: return state
	}
}

const selectUrlByProformType = (files, proformType) => {
	const item = files.filter(x=> x.proform_type === proformType)
				.sort(x => new Date(x.created))
				.reverse();

	if (item) return item[0]
	return null
}
export const selectProformUrls = (rateItems, files) => {
	return rateItems.map( item => {
		return {
			...item,
			fileUrl: selectUrlByProformType(files, item.proformType)?.file_url,
		}
	})
}

export default proformReducer ;