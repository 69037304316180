
import {
	useLocation,
} from 'react-router-dom';
import {
	useEffect,
	useState,
} from 'react';
import { connect } from 'react-redux';
import {
	useForm,
} from 'react-hook-form';

import {
	homeSettings,
	regexValidators,
} from 'src/constants';
import {
	Button,
	FontIcon,
	FormInput,
	FormErrorLabel,
} from 'src/components';

import {
	selectorMailingTemplates,
	selectorMailingTemplateByKey,
	selectorMailingLoadingStatus,
} from 'src/redux/mailing/mailingReducers';
import {
	fetchMailingTemplates,
	postMailing,
} from 'src/redux/mailing/mailingActions';

import 'src/containers/proform/ProformContainer.scss';

const defaultValues = {
	name: '',
	lastname: '',
	phone: '',
	email: '',
	company: '',
	message: '',
}


const ProformFormComponent = function(props) {
	const [submittedData, setSubmittedData] = useState({});
	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		formState: {errors, isSubmitSuccessful,}
	} = useForm({defaultValues, mode: 'onChange'});

	useEffect(()=> {
		if (formState.isSubmitSuccessful) {
      		reset({...defaultValues});
    	}
	}, [formState, submittedData, reset])

	const onSubmit = async (data)=> {
		if (props.isLoading) return ;

		setSubmittedData(data);
		const mailTemplate = selectorMailingTemplateByKey(
			props.templates, props.settings.templateKey
		)
		const payload = {
			payload: data,
			template: mailTemplate.uuid
		}
		const outcome = await props.dispatch(postMailing(payload)); // eslint-disable-line
	};

	return (
		<form>
			<h2>
				<FontIcon
					className='form-icon'
					icon='fa-solid fa-calculator'>
				</FontIcon>
				COTIZACIÓN {props.settings.title}
			</h2>

			<div className="form-item-name">
				<FormInput
					name="name"
					control={control}
					placeholder="Nombre"
					rules={{
						required:true,
						maxLength: {value: 25, message: 'Caracteres permitidos: 25'},
						pattern: {
							value: regexValidators.personName,
							message: 'Sólo se permite letras.'
						},
					}}
					defaultValue=""
				/>
				{errors.name && <FormErrorLabel message={errors.name?.message}/>}
			</div>
			<div className="form-item-lastname">
				<FormInput
					name="lastname"
					control={control}
					placeholder="Apellidos"
					rules={{
						required:true,
						maxLength: {value: 25, message: 'Caracteres permitidos: 25'},
						pattern: {
							value: regexValidators.personName,
							message: 'Sólo se permite letras.'
						},
					}}
					defaultValue=""
				/>
				{errors.lastname && <FormErrorLabel message={errors.lastname?.message}/>}
			</div>
			<div className="form-item-phone">
				<FormInput
					type="number"
					name="phone"
					control={control}
					placeholder="Teléfono"
					rules={{
						required:true,
						minLength: {value: 7, message: 'Caracteres mínimos: 7'},
						maxLength: {value: 12, message: 'Caracteres permitidos: 12'},
						pattern: {
							value: regexValidators.phoneNumber,
							message: 'Sólo se permite números.'
						},
					}}
					defaultValue=""
				/>
				{errors.phone && <FormErrorLabel message={errors.phone?.message}/>}
			</div>
			<div className="form-item-email">
				<FormInput
					type="email"
					name="email"
					control={control}
					placeholder="Email"
					rules={{
						required:true,
						maxLength: {value: 50, message: 'Caracteres permitidos: 50'},
						pattern: {
							value: regexValidators.emailAddress,
							message: 'Formato invalido',
						}
					}}
					defaultValue=""
				/>
				{errors.email && <FormErrorLabel message={errors.email?.message}/>}
			</div>
			<div className="form-item">
				<FormInput
					name="company"
					control={control}
					placeholder="Empresa"
					rules={{
						required:true,
						maxLength: {value: 50, message: 'Caracteres permitidos: 50'},
						pattern: {
							value: regexValidators.companyName,
							message: 'Sólo se permite números y letras.'
						},
					}}
				/>
				{errors.company && <FormErrorLabel message={errors.company?.message}/>}
			</div>
			<div className="form-item">
				<FormInput
					type="textarea"
					name="message"
					control={control}
					placeholder="Mensaje"
					rules={{
						required:true,
						minLength: {value: 70, message: 'Caracteres mínimos: 70'},
						maxLength: {value: 250, message: 'Caracteres permitidos: 250'},
					}}
				/>
				{errors.message && <FormErrorLabel message={errors.message?.message}/>}
			</div>
			<Button
				className={'control-button' + (props.isLoading? ' disabled': '')}
				onClick={handleSubmit(onSubmit)}
				text={props.isLoading ?'Procesando': 'Enviar'}
				>
			</Button>
		</form>
	)	
}

const mapStateToPropsProform = (state) => {
	return {
		isLoading: selectorMailingLoadingStatus(state),
	}
}
const ProformForm = connect(mapStateToPropsProform)(ProformFormComponent)

const ProformComponent = function(props) {
	const items = homeSettings.proform.items;
	const location = useLocation()

	useEffect(()=> {
		getMailTemplates();
	}, [])

	const getProformType = (items, location) => {
		const proformType = location.pathname.substr(1).replaceAll('/', '-')
		let item = items.filter(x => x.urlTag === `${proformType}`)
		if (item.length) {
			item = item[0]
		}
		return item;
	}

	const getMailTemplates = async ()=> {
		props.dispatch(fetchMailingTemplates())
	}

	const settings = getProformType(items, location);
	return props.templates ? (
		<div className="proform-form-layer"
			style={{ backgroundImage: `url(${settings.image.src})` }}>
			<ProformForm
				settings={settings}
				templates={props.templates}>
			</ProformForm>
		</div>
	): null
}

const mapStateToProps = (state) => {
	return {
		templates: selectorMailingTemplates(state),		
	}
}

export const ProformContainer = connect(mapStateToProps)(ProformComponent);
