
import {
	FETCH_PROFORM_FILES_REQUEST,
	FETCH_PROFORM_FILES_SUCCESS,
	FETCH_PROFORM_FILES_FAILURE,
	// INCREASE_PROFORM_COUNT,
	// DECREASE_PROFORM_COUNT,
} from './proformTypes';
import {
	selectAuthHeaders,
} from 'src/redux/auth/authReducers';
import endpoints from 'src/helpers/endpoints';
import { api } from 'src/helpers/init-axios';
// import { fetchAuthToken } from 'src/redux';

export const fetchProformFilesRequest = () => {
	return {
		type: FETCH_PROFORM_FILES_REQUEST,
	}
}

export const fetchProformFilesSuccess = (payload) => {
	return {
		type: FETCH_PROFORM_FILES_SUCCESS,
		payload: payload,
	}
}
export const fetchProformFilesFailure = (payload) => {
	return {
		type: FETCH_PROFORM_FILES_FAILURE,
		payload: payload,
	}
}

export const fetchProformFiles = () => {
	return async function(dispatch, getState){
		try {
			const state = getState();
			const headers = selectAuthHeaders(state);
			dispatch(fetchProformFilesRequest())
			const rsp = await api.get(
				endpoints.fetchProformFiles(), headers
			)
			return dispatch(fetchProformFilesSuccess(rsp.data))
		} catch (err) {
			return dispatch(fetchProformFilesFailure(err.message))
		}
	}
}