import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
export {
	decodeJwt,
	decodeJwtExp,
} from 'src/helpers/utils';

export const withRouter = (Component) => {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
		  <Component
		    {...props}
		    router={{ location, navigate, params }}
		  />
		);
	}

  	return ComponentWithRouterProp;
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));