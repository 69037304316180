
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "src/components/SliderComponent.scss";
import { Carousel } from 'react-responsive-carousel';

export function SliderComponent(props) {
	const items = props.items;
	return (
		<div className={props.className ? props.className : 'slider-layer'} >
			<Carousel
				showArrows={true}
				infiniteLoop={true}
				showStatus={true}
				showIndicators={false}
				useKeyboardArrows={true}
				autoPlay={true}
				stopOnHover={true}
				swipeable={true}
				dynamicHeight={false}
				emulateTouch={true}
			>
				{items.length ? items.map(item => {
					return (
						<figure key={item.id} className="carousel-item">
							<img
								src={item.component}
								alt={item.legend}
							>
							</img>
						</figure>
					)
				}) : null}
			</Carousel>
		</div>
	)
}