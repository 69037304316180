

import {
	FETCH_MAILING_TEMPLATES_REQUEST,
	FETCH_MAILING_TEMPLATES_SUCCESS,
	FETCH_MAILING_TEMPLATES_FAILURE,
	POST_MAILING_REQUEST,
	POST_MAILING_SUCCESS,
	POST_MAILING_FAILURE,
} from './mailingTypes';


const initialState = {
	loading: false,
	templates: [],
	error: '',
	postMailing: {},
}

const mailingTemplateReducer = (state = initialState, action) => {
	switch(action.type){
		case FETCH_MAILING_TEMPLATES_REQUEST: return {
			...state,
			loading: true,
		}
		case FETCH_MAILING_TEMPLATES_SUCCESS: return {
			...state,
			loading: false,
			templates: action.payload,
			error: '',
		}
		case FETCH_MAILING_TEMPLATES_FAILURE: return {
			...state,
			loading: false,
			templates: '',
			error: action.payload,
		}
		case POST_MAILING_REQUEST: return {
			...state,
			loading: true,
		}
		case POST_MAILING_SUCCESS: return {
			...state,
			loading: false,
			postMailing: action.payload,
			error: '',
		}
		case POST_MAILING_FAILURE: return {
			...state,
			loading: false,
			postMailing: {},
			error: action.payload,
		}
		default: return state
	}
}

export const selectorMailingLoadingStatus = (state) => state.mailing?.loading || false;
export const selectorMailingTemplates = (state) => state.mailing?.templates || [];
export const selectorMailingTemplateByKey = (templates, key) => {
	const outcome = templates.filter(x => x.key === key)
	if (outcome) {
		return outcome[0]
	}
	return ;
}

export default mailingTemplateReducer ;