
import { homeSettings } from 'src/constants';
import {
	SectionItemContent,
	SectionItemImage,
} from 'src/components';

function SectionItem(props){
	const settings = props.settings;
	return (
		<div
			ref={props.layerRef}
			className="section-item">
			<SectionItemContent
				title={settings.title}
				paragraph={settings.paragraph}
				>
			</SectionItemContent>
			<SectionItemImage image={settings.image}>
			</SectionItemImage>
		</div>
	)
}

export function MainWeContainer(props) {
	const home = homeSettings.home;
	return (
		<div
			id={props.id ? props.id:null}
			className={props.className ? props.className : null}
			ref={props.reference}
			style={{
				backgroundImage: `url(${home.background})`,
				backgroundRepeat: "repeat-x",
				backgroundSize: "85%",
			}}
			>
			<SectionItem settings={home}></SectionItem>
		</div>
	)
}