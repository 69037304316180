
import { Slider } from "src/components";
import 'src/containers/MainContainer/MainContainer.scss'
import { MainWeContainer } from 'src/containers/MainContainer/MainWeContainer.js';
import { MainServicesContainer } from 'src/containers/MainContainer/MainServicesContainer.js';
import { MainOperationsContainer } from 'src/containers/MainContainer/MainOperationsContainer.js';
import { MainProformContainer } from 'src/containers/MainContainer/MainProformContainer.js';
import { MainLocationContainer } from 'src/containers/MainContainer/MainLocationContainer.js';
import { MainContactContainer } from 'src/containers/MainContainer/MainContactContainer.js';
import { sliderList } from 'src/constants';

export function MainContainer(props) {
	return (
		<div className="main-layer">
			<Slider items={sliderList}></Slider>
			<MainWeContainer 
				reference={props.references.home}
				className={"main-we"}
				id={'home'}>
			</MainWeContainer>
			<MainServicesContainer
				reference={props.references.services}
				className={"main-services"}
				id={'services'}>
			</MainServicesContainer>
			<MainOperationsContainer
				reference={props.references.operations}
				className={"main-operations"}
				id={'operations'}>
			</MainOperationsContainer>
			<MainProformContainer
				reference={props.references.proform}
				className={"main-proform"}
				id={'proform'}>
			</MainProformContainer>
			<MainContactContainer
				reference={props.references.contact}
				className={"main-contact"}
				id={'contact'}>
			</MainContactContainer>
			<MainLocationContainer
				reference={props.references.location}
				className={"main-location"}
				id={'location'}>
			</MainLocationContainer>
		</div>
	)
}