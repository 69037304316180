import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'src/components/InputComponent.scss';

library.add(fas);



export function InputTextComponent(props) {
	return (
		<input type={props.type || "text"}
			className="control-text"
			name={props.name}
			placeholder={props.placeholder}
			disabled={props.disabled || false}
			readOnly={props.readOnly || false}
			ref={props.inputRef || null}
			onChange={props.onChange}
			onBlur={props.onBlur}
			>
		</input>
	)
}

export function InputTextWithIconComponent(props) {
	return (
		<span className="control-text-icon">
			<FontAwesomeIcon  icon={props.icon || 'pen'} />
			<InputTextComponent
				type="text"
				{...props}
			/>
		</span>
	)
}
export function InputEmailComponent(props) {
	return (
		<InputTextComponent
			type="email"
			{...props}
		/>
	)
}

export function TextAreaComponent(props) {
	return (
		<textarea
			className="control-textarea"
			name={props.name}
			placeholder={props.placeholder}
			disabled={props.disabled || false}
			readOnly={props.readOnly || false}
			ref={props.inputRef || null}
			onChange={props.onChange}
			onBlur={props.onBlur}
			></textarea>
	)
}