
import { useRef } from 'react';
import {
  Routes as Switch,
  Route,
} from 'react-router-dom';

import { Provider, connect } from 'react-redux';
import store from 'src/redux/store';
import {
  fetchAuthToken
} from 'src/redux';
import {
  selectAccessToken,
} from 'src/redux/auth/authReducers';

import {
  Header,
  Main,
  Footer,
} from 'src/containers';
import {
  RatesRouter,
  ProformRouter,
  TrackingRouter,
} from 'src/routes';
import './App.scss';
import 'src/media/styles/media-query.scss';


store.dispatch(fetchAuthToken())


function AppContentComponent(props) {
  const homeRef = useRef();
  const serviceRef = useRef();
  const operationRef = useRef();
  const proformRef = useRef();
  const contactRef = useRef();
  const references = {
    home: homeRef,
    services: serviceRef,
    operations: operationRef,
    proform: proformRef,
    contact: contactRef,
  }

  const handleClick = (e) => {
    const data = e.target?.href.split('/')
    const reference = references[data[data.length - 1]];
    if (reference) {
      reference.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const navHandlers = {
    home: handleClick,
    services: handleClick,
    operations: handleClick,
    proform: handleClick,
    contact: handleClick,
  }

  return props.isAppAuthenticated ? (<div className="body-layer">
      <Header navHandlers={navHandlers}></Header>
      <Switch>
        <Route exact path="/"
          element={<Main references={references} />}>
        </Route>
        <Route exact path="/home"
            element={<Main references={references} />}>
        </Route>
        <Route exact path="/services"
            element={<Main references={references} />}>
        </Route>
        <Route exact path="/operations"
            element={<Main references={references} />}>
        </Route>
        <Route exact path="/proform"
            element={<Main references={references} />}>
        </Route>
        <Route exact path="/contact"
            element={<Main references={references} />}>
        </Route>
        
      </Switch>
      <RatesRouter></RatesRouter>
      <ProformRouter></ProformRouter>
      <TrackingRouter></TrackingRouter>
      <Footer></Footer>
    </div>) : null;
}

const mapStateToProps = (state) => {
  return {
    isAppAuthenticated: selectAccessToken(state),
  }
}

const AppContent = connect(mapStateToProps)(AppContentComponent);

function App() {
  return (
    <Provider store={store}>
       <AppContent></AppContent>
    </Provider>
  );
}



export default App;
