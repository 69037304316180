
import {
	Controller,
} from 'react-hook-form';
import {FontIcon} from 'src/components';

export const FormErrorLabelComponent = (props) => {
	return (
		<span className="error">
			<FontIcon
				className='form-icon'
				icon='fa-solid fa-triangle-exclamation'>
			</FontIcon>
			{ props.message? props.message : 'Valor Invalido'}
		</span>
	)
}
export const FormInputComponent = (props) => {

	const inputTextRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="text"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
		/>
	)
	const textAreaRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<textarea
			className="control-textarea"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
		/>
	)
	const inputNumberRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="number"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
		/>
	)
	const inputEmailRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="email"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
		/>
	)

	return (
		<Controller
			defaultValue={props.defaultValue}
			control={props.control}
			name={props.name}
			rules={props.rules}
			render={ props.type === 'textarea' ? textAreaRender:
						props.type === 'number' ? inputNumberRender :
						props.type === 'email' ? inputEmailRender : inputTextRender }
		/>
	)
}