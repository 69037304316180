
import { useNavigate } from 'react-router-dom';
import { homeSettings } from 'src/constants';
import { 
	SectionItemImage,
	Button,
} from 'src/components';
import {
	RatesRouter,
	TrackingRouter,
} from 'src/routes';

const OperationCard = function (props){
	const operation = props.settings;
	const navigate = useNavigate();

	const ratesHandler = (e) => {
		e.preventDefault();
		const route = RatesRouter();
		navigate(route.props.children.props.path);
	}
	const trackingHandler = (e) => {
		e.preventDefault();
		const route = TrackingRouter();
		navigate(route.props.children.props.path);
	}

	return (
		<div className="operation-card" >
			<h2>{operation.name}</h2>
			{ operation.items.length? operation.items.map(item=> {
				const IconComponent = item.image.svg;
				return (
					<p key={item.id}>
						<IconComponent />
						<label>{item.paragraph}</label>
					</p>
				)
			}): null}
			<Button text={'Tracking'}
				onClick={trackingHandler}></Button>
			<Button text={'Tarifas'}
				onClick={ratesHandler}></Button>
		</div>
	)
}

export function MainOperationsContainer(props){
	const settings = homeSettings.operations;
	return (
		<div
			ref={props.reference ? props.reference:null}
			style={{ backgroundImage: `url(${settings.background})` }}
			className="main-operations">
			
			<SectionItemImage
				className={'operation-image'}
				image={settings.image}>				
			</SectionItemImage>
			<OperationCard settings={settings}></OperationCard>
		</div>
	)
}