
import AirFreight from 'src/media/images/air-freight.webp';
import OceanFreight from 'src/media/images/ocean-freight.webp';
import LandFreight from 'src/media/images/land-freight.webp';

export const sliderList = [
	{
		id: 1,
		component: AirFreight,
		legend: 'Air Freight'
	},
	{
		id: 2,
		component: OceanFreight,
		legend: 'Ocean Freight'
	},
	{
		id: 3,
		component: LandFreight,
		legend: 'Land Freight'
	},
]