
import { homeSettings } from 'src/constants';
import {
	SectionItemContent,
} from 'src/components';

function SectionItemBackground(props) {
	return (
		<div className="section-item-bg" style={
			{ backgroundImage: `url(${props.background})` }
		}></div>
	)
}
function SectionItem(props) {
	const settings = props.settings;
	const IconComponent = settings.image.svg
	return (
		<div className="section-item">
			<SectionItemBackground
				background={settings.background}>
			</SectionItemBackground>
			<a className="section-item-img"
				href="/"
				onClick={(e) => e.preventDefault()}>
				<IconComponent />
			</a>
			<SectionItemContent
				title={settings.title}
				paragraph={settings.paragraph}
			>
			</SectionItemContent>

		</div>
	)
}

export function MainServicesContainer(props) {
	const service = homeSettings.services;
	return (
		<div
			id={props.id ? props.id : null}
			className={props.className ? props.className : null}
			ref={props.reference}
			>
			{service.items.length ? service.items.map(item=> {
				return (
					<SectionItem 
						key={item.id}
						settings={item}>
					</SectionItem>
				)
			}):null}
		</div>
	)
}