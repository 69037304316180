

import { homeSettings } from 'src/constants';
import {
	Button,
	InputText,
} from 'src/components';

import 'src/containers/tracking/TrackingContainer.scss';

const TrackingHeader = function(props) {
	return (
		<div className="tracking-header">
			<h2>{props.title}</h2>
		</div>
	)
}
const TrackingCard = function(props) {
	return (
		<div className="tracking-card">
			<InputText
				placeholder="Introdusca número de seguimiento"
				>
			</InputText>
			<Button text='RASTREAR'></Button>
		</div>
	)
}

export const TrackingContainer = function(props) {
	const settings = homeSettings.tracking;
	return (
		<div className="tracking-layer"
			style={{ backgroundImage: `url(${settings.background})` }}>
			<TrackingHeader title={settings.title}></TrackingHeader>
			<TrackingCard></TrackingCard>
		</div>
	)
}