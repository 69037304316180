
import {
	FETCH_AUTH_TOKEN_REQUEST,
	FETCH_AUTH_TOKEN_SUCCESS,
	FETCH_AUTH_TOKEN_FAILURE,
	START_AUTH_HEART_BEAT,
	STOP_AUTH_HEART_BEAT,
} from './authTypes';
import {
	selectHeartBeat,
	selectDecodedToken,
} from 'src/redux/auth/authReducers';
import endpoints from 'src/helpers/endpoints';
import { api } from 'src/helpers/init-axios';
import environment from 'src/environment';

export const fetchAuthTokenRequest = () => {
	return {
		type: FETCH_AUTH_TOKEN_REQUEST,
	}	
}

export const fetchAuthTokenSuccess = (payload) => {
	return {
		type: FETCH_AUTH_TOKEN_SUCCESS,
		payload: payload,
	}	
}

export const fetchAuthTokenFailure = (payload) => {
	return {
		type: FETCH_AUTH_TOKEN_FAILURE,
		payload: payload,
	}	
}

export const startAuthHeartBeat = (payload) => {
	return {
		type: START_AUTH_HEART_BEAT,
		payload: payload,
	}		
}

export const stopAuthHeartBeat = () => {
	return {
		type: STOP_AUTH_HEART_BEAT,
		payload: {},
	}		
}

export const startHeartBeat = () => {
	return async function(dispatch, getState){
		const state = getState()
		if (selectHeartBeat(state)) return;

		const invokeAuthHeartBeat = () => {
			dispatch(fetchAuthToken())
		}
		const heartBeatInterval = setInterval(() => {
			invokeAuthHeartBeat()
		}, environment.authCredentials.refreshToken)
		await dispatch(startAuthHeartBeat(heartBeatInterval))
	}
}

const fetchAccessToken =  () => {
	return async function(dispatch, getState) {
		const payload = {
			username: environment.authCredentials?.username,
			password: environment.authCredentials?.password,
		}
		const rsp = await api.post(
			endpoints.fetchAuthToken(), payload
		)
		localStorage.setItem('jwtifo', JSON.stringify(rsp.data));
		dispatch(fetchAuthTokenSuccess(rsp.data))
	}
}

const fetchRefreshAccessToken = () => {
	return async function (dispatch, getState) {
		const authData = JSON.parse(localStorage.getItem('jwtifo') || '{}')
		const payload = {
			refresh: authData.refresh,
		}
		try {
			const rsp = await api.post(
				endpoints.fetchAuthRefreshToken(), payload
			)
			authData.access = rsp.data.access
			localStorage.setItem('jwtifo', JSON.stringify(authData));
			dispatch(fetchAuthTokenSuccess(authData))
		} catch (err) {
			if (err.response.status === 401) {
				dispatch(fetchAccessToken())
			} else {
				throw err.message
			}
		}
	}
}

export const fetchAuthToken = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchAuthTokenRequest())
			const authData = JSON.parse(localStorage.getItem('jwtifo') || '{}')
			if (authData.refresh && selectDecodedToken(authData.refresh).hours >= 8) {
				await dispatch(fetchRefreshAccessToken())
			} else {
				await dispatch(fetchAccessToken())
			}
			await dispatch(startHeartBeat())
		} catch (err) {
			console.log(err.message)
			dispatch(fetchAuthTokenFailure(err.message))
		}
	}
}