
import { useState } from 'react';
import {
	Navigator,
	Logo,
	NavigatorSandwich,
} from 'src/components';
import { navigatorList } from 'src/constants';
import 'src/containers/HeaderContainer.scss';

export const HeaderContainer = function (props) {
	const [sandwich, setSandwich] = useState(true);
	const items = navigatorList;
	const handlers = props.navHandlers || {};
	if (handlers) {
		// update the click event of the nav links
		items.forEach((item) => {
			const handler = handlers[item.name];
			if (handler) {
				item.onClick = handler;
			}
		})
	}

	const sandwichOnClick = (e) => {
		e.preventDefault();
		setSandwich(prevState => prevState ? false : true)
	}

	return (
		<header className="header-layer">
			<Logo></Logo>
			<NavigatorSandwich
				onClick={sandwichOnClick}>
			</NavigatorSandwich>
			<Navigator items={items} sandwich={sandwich}>
			</Navigator>
		</header>
	)
}
