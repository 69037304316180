
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProformFiles } from 'src/redux';
import { selectProformUrls } from 'src/redux/proform/proformReducers';
import { homeSettings } from 'src/constants';
import {
	SectionItemContent,
	SectionItemImage,
	Button,
} from 'src/components';

import 'src/containers/rates/RatesContainer.scss';

const RatesCard = function(props) {
	return (
		<div className="rates-content-card">
			<SectionItemImage image={props.image}></SectionItemImage>
			<SectionItemContent
				title={props.title}
				paragraph={props.paragraph}>
			</SectionItemContent>
			{ props.fileUrl ?
				<Button
					href={props.fileUrl}
					text={props.buttonTitle}>
				</Button> : null}
		</div>
	)
}
const RatesContent = function(props) {
	const items = selectProformUrls(
		props.items, props.files
	)
	return (
		<div className="rates-content">
			{ items.length? items.map( item => {
				return (
					<RatesCard
						key={item.id}
						image={item.image}
						title={item.title}
						paragraph={item.paragraph}
						buttonTitle={item.buttonTitle}
						fileUrl={item.fileUrl}
						>
					</RatesCard>
				)
			}):null}
		</div>
	)
}

const RatesHeader = function(props) {
	return (
		<div className="rates-header">
			<h2>{props.title}</h2>
		</div>
	)
}

const RatesComponent = function(props){
	const settings = homeSettings.rates;
	useEffect(()=> {
		getProformFiles()
	}, []) // eslint-disable-line

	const getProformFiles = async ()=> {
		props.dispatch(fetchProformFiles())
	}
	return props.files? (
		<div
			className="rates-layer"
			style={{ backgroundImage: `url(${settings.background})` }}
			>
			<RatesHeader title={settings.title}></RatesHeader>
			<RatesContent
				files={props.files}
				items={settings.items}>
			</RatesContent>
		</div>
	): null
}

const mapStateToProps = (state) => {
	return {
		files: state.proform.proformFiles,
	}
}

export const RatesContainer = connect(mapStateToProps)(RatesComponent)