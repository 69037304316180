

export const navigatorList = [
	{
		key: 1,
		description: "Nosotros",
		className: "navigator-item",
		onClick: null,
		path: '/home',
		name: 'home',
	},
	{
		key: 2,
		description: "Servicios",
		className: "navigator-item",
		onClick: null,
		path: '/services',
		name: 'services',
	},
	{
		key: 3,
		description: "Operaciones",
		className: "navigator-item",
		onClick: null,
		selected: false,
		path: '/operations',
		name: 'operations',
	},
	{
		key: 4,
		description: "Tarifas",
		className: "navigator-item",
		onClick: null,
		path: '/rates',
		name: 'rates',
	},
	{
		key: 5,
		description: "Cotizar",
		className: "navigator-item",
		onClick: null,
		path: '/proform',
		name: 'proform',
	},
	{
		key: 6,
		description: "Contacto",
		className: "navigator-item",
		onClick: null,
		path: '/contact',
		name: 'contact',
	},
];