
import { homeSettings } from 'src/constants';
import { FontIcon } from 'src/components';
import 'src/containers/FooterContainer.scss';

const FooterContact = function(props) {
	const settings = props.settings;
	const labels = [
		{	
			id: 1,
			label: 'Dirección:', value: settings.address},
		{	
			id: 2,
			label: 'Teléfono:', value: settings.phone },
		{	
			id: 3,
			label: 'Email:', value: settings.email },
	]
	return (
		<article className="footer-contact">
			<h2>{settings.name}</h2>
			{labels.length ? labels.map(item => {
				return (
					<p key={item.id}>
						<b>{item.label}</b> {item.value}
					</p>
				)
			}):null}
		</article>
	)
}

const FooterSocial = function(props) {
	return (
		<div className="footer-social">
			<figure>
				<img src={props.image.src}
					alt={props.image.alt}></img>
			</figure>
			<span>
				<label>Siguenos en: </label>
				{props.icons.length ? props.icons.map(item=> {
					return (<a href="#" key={item.id}>
						<FontIcon
							icon={item.icon}
							className={item.className}></FontIcon>
					</a>)
				}):null}
			</span>
		</div>
	)
}

export function FooterContainer(props) {
	const settings = homeSettings.footer;
	return (
		<div
			style={{backgroundImage: `url(${settings.background})`}}
			className="footer-layer">
			<FooterSocial
				icons={settings.icons} 
				image={settings.image}>
			</FooterSocial>
			<FooterContact settings={settings}></FooterContact>
		</div>
	)
}