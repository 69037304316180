
import HomeImage from 'src/media/images/we-are.webp';
import HomeBackground from 'src/media/images/we-are-background.png';

import { ReactComponent as ServiceAirImage } from 'src/media/images/service-air.svg';
import { ReactComponent as ServiceOceanImage } from 'src/media/images/service-ocean.svg';
import { ReactComponent as ServiceLandImage } from 'src/media/images/service-land.svg';

import ServiceAirBg from 'src/media/images/service-air-bg.webp';
import ServiceOceanBg from 'src/media/images/service-ocean-bg.webp';
import ServiceLandBg from 'src/media/images/service-land-bg.webp';

import OperationBackground from 'src/media/images/operation-bg.png';
import { ReactComponent as OperationCheckImage } from 'src/media/images/operation-check.svg';
import OperationTruckImage from 'src/media/images/operation-truck.png';

import ProformAirImage from 'src/media/images/proform-air.webp';
import ProformOceanImage from 'src/media/images/proform-ocean.webp';
import ProformLandImage from 'src/media/images/proform-land.webp';

import FooterLogoImage from 'src/media/ifosac-logo.png';
import FooterBackground from 'src/media/images/footer-bg.png';

import ContactEmployeeImage from 'src/media/images/contact-employee.webp';

import RatesBackground from 'src/media/images/footer-bg.png';
import RatesGeneralImage from 'src/media/images/rates-general.png';
import RatesAirImage from 'src/media/images/rates-air.png';
import RatesOceanImage from 'src/media/images/rates-ocean.png';
import RatesLandImage from 'src/media/images/rates-land.png';

import TrackingBackground from 'src/media/images/tracking-bg.webp';

const serviceList = [
	{
		id: 1,
		image: { svg: ServiceOceanImage , alt:''},
		background: ServiceOceanBg,
		title: 'SERVICIO MARÍTIMO',
		paragraph: "Exportación Marítima Cargas FCL",
	},
	{
		id: 2,
		image: { svg: ServiceAirImage, alt: '' },
		background: ServiceAirBg,
		title: 'SERVICIO AÉREO',
		paragraph: "Exportación e Importación Aérea.",
	},
	{
		id: 3,
		image: { svg: ServiceLandImage, alt: '' },
		background: ServiceLandBg,
		title: 'SERVICIO TRANSPORTE',
		paragraph: "Consolidación de Carga Aérea.",
	}
];
const operationList = [
	{
		id: 1,
		image: { svg: OperationCheckImage , alt:''},
		title: null,
		paragraph: 'Revisa nuestros tarifarios',
	},
	{
		id: 2,
		image: { svg: OperationCheckImage, alt: '' },
		title: null,
		paragraph: 'Estado y rastreo de embarque',
	},
];
const proformList = [
	{
		id: 1,
		image: { src: ProformOceanImage, alt: 'Vía Marítima' },
		title: 'VIA MARÍTIMA',
		paragraph: 'Vía Marítima',
		buttonLabel: 'Cotizar',
		urlTag: 'proform-ocean',
		templateKey: 'OCEAN_PROFORM',
	},
	{
		id: 2,
		image: { src: ProformAirImage, alt: 'Vía Aérea' },
		title: 'VIA AÉREA',
		paragraph: 'Vía Aérea',
		buttonLabel: 'Cotizar',
		urlTag: 'proform-air',
		templateKey: 'AIR_PROFORM',
	},
	{
		id: 3,
		image: { src: ProformLandImage, alt: 'Vía Terrestre' },
		title: 'VIA TERRESTRE',
		paragraph: 'Vía Terrestre',
		buttonLabel: 'Cotizar',
		urlTag: 'proform-land',
		templateKey: 'LAND_PROFORM',
	},
];

const footerIconList = [
	{ 	id: 1,
		icon: 'fa-brands fa-twitter',
		className: 'footer-icon' },
	{ 	id: 2,
		icon: 'fa-brands fa-facebook-f',
		className: 'footer-icon' },
	{ 	id: 3,
		icon: 'fa-brands fa-instagram',
		className: 'footer-icon' },
	{ 	id: 4,
		icon: 'fa-brands fa-youtube',
		className: 'footer-icon' },
]

const ratesList = [
	{
		id: 1,
		image: { src: RatesGeneralImage, alt: 'Tarifaro General', },
		title: 'TARIFARIO GENERAL',
		paragraph: 'Tarifas de servicios.',
		buttonTitle: 'Descargar',
		proformType: 'GENERAL',
	},
	{
		id: 2,
		image: { src: RatesOceanImage, alt: 'Servicio Marítimo', },
		title: 'SERVICIO MARÍTIMO',
		paragraph: 'Exportación Marítima cargas FCL.',
		buttonTitle: 'Descargar',
		proformType: 'OCEAN',
	},
	{
		id: 3,
		image: { src: RatesAirImage, alt: 'Servicio Aéreo', },
		title: 'SERVICIO AÉREO',
		paragraph: 'Exportación e Importación Aérea.',
		buttonTitle: 'Descargar',
		proformType: 'AIR',
	},
	{
		id: 4,
		image: { src: RatesLandImage, alt: 'Servicio Terrestre', },
		title: 'SERVICIO TERRESTRE',
		paragraph: 'Consolidación de Carga Aérea.',
		buttonTitle: 'Descargar',
		proformType: 'LAND',
	},
]

export const homeSettings = {
	home: {
		background: HomeBackground,
		image: { src: HomeImage, alt: '' },
		title: '¿Quiénes Somos?',
		paragraph: "INTERNACIONAL FREIGHT OPERATOR SAC empresa de capital peruano certificada como agente de Carga Internacional IATA, iniciando operaciones desde el 25 febrero del 2013 dedicada al manejo de animales vivos, carga DGR, Valorados y carga en general a todas partes del mundo. Nos hemos convertido en una empresa de carga internacional AÉREO Y MARITIMO confiable en el Peru y el resto del mundo brindando eficiencia para nuestro clientes.",
	},
	services: {
		items: serviceList,
	},
	operations: {
		name: 'OPERACIONES',
		background: OperationBackground,
		image: { src: OperationTruckImage, alt: 'Operations' },
		items: operationList,
	},
	proform: {
		name: 'COTIZAR',
		description: 'Envíanos tu cotización con el embarque de tu preferencia:',
		items: proformList,
	},
	footer: {
		name: 'CONTACTO',
		background: FooterBackground,
		image: { src: FooterLogoImage, alt: "International Freight Operator", },
		icons: footerIconList,
		address: 'Av. Elmer Faucett Cdra. 30 C.A.C. Oficina 308 A - Callao',
		phone: '(01) 5746445 / +51 998372599',
		email: 'jvasquez@ifosac.com',
		workingHours: 'Lunes a Viernes de 8:30 a.m. a 5:30 p.m',
		googleApiKey: 'AIzaSyDPZOqZegUaJho8H_Gq-fQ4GJqTQ1aqIGE',
		googlePlaceId: 'place_id:ChIJBVlOvB_MBZERvK6D4gP4sRM',
	},
	contact: {
		name: 'CONTÁCTANOS',
		image: { src: ContactEmployeeImage, alt: "", },
		description: "Envíanos tus consultas para obtener más információn de nuestros servicios. Te atenderemos en la brevedad posible.",
		templateKey: 'CONTACT',
	},
	rates: {
		title: 'TARIFAS',
		items: ratesList,
		background: RatesBackground,
	},
	tracking: {
		title: 'TRACKING',
		background: TrackingBackground,
	},
};